// @ts-nocheck

import { useState } from 'react';
import axiosHttpClient from '../infra/http/AxiosHttpClient';

class JobsController {
    private async fetchJobs(): Promise<{} | null> {
        try {
            const response = await axiosHttpClient.get("/schedule/jobs");
    
            const jobs: object = response.data;
    
            return jobs;
    
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    
    async getNumberOfJobsByLeagues(): Promise<[] | null> {
        try {
            var jobs = await this.fetchJobs();
            var jobsByLeagues: [] = [];
    
            if (jobs !== null) {
                Object.keys(jobs).forEach((key) => {
                    if(jobs[key].length > 0) {
                        jobsByLeagues.push({id: key, value: jobs[key].length, label: key});
                    }
                });
            }    
            return jobsByLeagues;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }
    
    async getNumberOfJobsInProgressByLeagues(): Promise<[] | null> {
        try {
            var jobs = await this.fetchJobs();
            var jobsInProgressByLeagues: [] = [];
    
            if (jobs !== null) {
                Object.keys(jobs).forEach((key) => {
                    var count = 0;
                    if(jobs[key].length > 0) {
                        jobs[key].forEach((job) => {
                            if(job["game"]["status"] === "inprogress") {
                                count++;
                            }
                        });
                        if(count > 0) {
                            jobsInProgressByLeagues.push({id: key, value: count, label: key});
                        }
                    }
                });
            }    
            return jobsInProgressByLeagues;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }

    async createJobs() {
        try {
            const response = await axiosHttpClient.post("/schedule/jobs");
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    }

    async deleteJobs() {
        try {
            const response = await axiosHttpClient.post("/schedule/stopGames");
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    }
}


export default JobsController;