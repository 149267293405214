import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

export default function CustomPieChart({ data, width = 400, height = 400}: { data: any, width?: number , height?: number }) {
  return (
    <PieChart
    margin={{ top: 20, bottom: 20 }}
      series={[
        {
          data: data,
          cx: '65%',
        },
      ]}
      slotProps={{
        legend: {
          direction: 'row',
          position: { vertical: 'bottom', horizontal: 'middle' },
          
        },
      }}
      width={width}
      height={height}
    />
  );
}