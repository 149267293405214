import { useState } from 'react';
import axiosHttpClient from '../infra/http/AxiosHttpClient';

interface Leagues {
  leaguesEnabled: string[];
  leaguesDisabled: string[];
}

async function fetchLeagues(): Promise<Leagues | null> {
  try {
    const response = await axiosHttpClient.get("/configuration/leagues");

    const leagues: Leagues = {
      leaguesEnabled: response.data.leaguesEnabled,
      leaguesDisabled: response.data.leaguesDisabled,
    };

    return leagues;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default fetchLeagues();