import React, { useState } from "react";
import { Button, TextField, Paper, Typography } from '@mui/material';
import AuthController from "../../controllers/authController";
import { Navigate } from "react-router-dom";

const Login: React.FC = () => {
    const { login, isAuthenticated } = AuthController();
    const [loginAttempted, setLoginAttempted] = useState<boolean>(false);

    const [token, setToken] = useState<string>('');

    const handleLogin = () => {
        login(token);    
        setLoginAttempted(true);
    };

    if (isAuthenticated) {
        return <Navigate to="/dashboard" />
    }

    return (
        <Paper elevation={3} style={{ padding: '16px', maxWidth: '300px', margin: 'auto', marginTop: '50px' }}>
            <Typography variant="h5" gutterBottom>
                Login
            </Typography>
            <TextField 
            label="Token" 
            fullWidth margin="normal" 
            onChange={(e) => setToken(e.target.value)}
            error={loginAttempted && !isAuthenticated} 
            />
            <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
                Login
            </Button>
        </Paper>
    );
};

export default Login;