import axiosHttpClient from '../infra/http/AxiosHttpClient';


class CrashAnalyticsController {
  public async fetchTodayCrashedGames(): Promise<{ [key: string]: number } | null> {
    try {
      const response = await axiosHttpClient.get("/crash_analytics/get_today_crashed_games");
  
      const crashedGames: { [key: string]: number } = response.data;
  
      return crashedGames;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  
  async fetchLastWeekCrashedGames(): Promise<{ [key: string]: number } | null> {
    try {
      const response = await axiosHttpClient.get("/crash_analytics/get_crashed_games_of_last_week");
  
      const crashedGames: { [key: string]: number } = response.data;
  
      return crashedGames;
    } catch (error) {
      console.error(error);
      return null;
    }
  }


  async fetchLastWeekPercentageCrashedGames(): Promise<{ [key: string]: string } | null> {
    try {
      const response = await axiosHttpClient.get("/crash_analytics/get_percentage_of_crashes_in_last_week");
  
      const crashedGames: { [key: string]: string } = response.data;
  
      return crashedGames;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

}



export default CrashAnalyticsController;