import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export default function CustomButton({ text, width, height, backgroundColor, onClick }: { text: string, width: number, height: number, backgroundColor: string, onClick: any }) {
  return (
    <Button variant="contained" sx={{ "width": { width }, "height": { height }, "backgroundColor": {backgroundColor} }} onClick={onClick}>
      {text}
    </Button>
  );
}