import { useState } from 'react';
import axiosHttpClient from '../infra/http/AxiosHttpClient';

interface AuthController {
  isAuthenticated: boolean;
  login: (token: string) => void;
  logout: () => void;
}

const AuthController = (): AuthController => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkTokenValidity = async (token: string) => {

    localStorage.setItem("token", "");

    try {
      const response = await axiosHttpClient.post("/authentication/token", 
      {
        token: token,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
      );
      console.log(response);
      return response.status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  const login = async (token: string) => {
    var isTokenValid: boolean = await checkTokenValidity(token);

    if(isTokenValid){
        setIsAuthenticated(true);
        localStorage.setItem("token", token);
    }
    else{
        console.log("Error ao tentar fazer o Login")
    }
    
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.setItem("token", "");
  };

  return {
    isAuthenticated,
    login,
    logout,
  };
};

export default AuthController;
