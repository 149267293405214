import axios from "axios";
import { API_BASE_URL } from "../../ApplicationConstants";

const axiosHttpClient = axios.create({
    baseURL: API_BASE_URL,
});

axiosHttpClient.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
        config.headers["X-API-Key"] = token;
    }

    return config;
});

export default axiosHttpClient;
