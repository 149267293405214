import React, { useEffect, useState } from 'react';
import CustomAppBar from "../../components/CustomAppBar";
import CustomPieChart from "../../components/CustomPieChart";
import Grid from "@mui/material/Grid";
import CustomButton from "../../components/CustomButton";
import { Box, Card, IconButton, ListItemIcon, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { dividerStyle } from "./styles";
import fetchLeagues from "../../controllers/leaguesController";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import JobsController from '../../controllers/jobsController';
import CustomModal from '../../components/CustomModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CrashAnalyticsController from '../../controllers/crashAnalyticsController';


const Dashboard: React.FC = () => {
    const jobsController = new JobsController();
    const crashAnalyticsController = new CrashAnalyticsController();

    var [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    var [leaguesEnabled, setLeaguesEnabled] = useState<string[]>([]);
    var [leaguesDisabled, setLeaguesDisabled] = useState<string[]>([]);
    var [numberOfJobsByLeagues, setNumberOfJobsByLeagues] = useState<object[]>([]);

    var [numberOfJobsInprogressByLeagues, setNumberOfJobsInprogressByLeagues] = useState<object[]>([]);

    var [fetchCrashedGames, setFetchCrashedGames] = useState<{ [key: string]: number }>({});
    var [fetchLastWeekCrashedGames, setFetchLastWeekCrashedGames] = useState<{ [key: string]: number }>({});
    var [fetchLastWeekPercentageCrashedGames, setfetchLastWeekPercentageCrashedGames] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        await fetchLeagues.then((data) => {
            if (data) {
                setLeaguesEnabled(data.leaguesEnabled);
                setLeaguesDisabled(data.leaguesDisabled);
            }
        });
        await jobsController.getNumberOfJobsByLeagues().then((data) => {
            if (data) {
                setNumberOfJobsByLeagues(data);
            }
        });
        await jobsController.getNumberOfJobsInProgressByLeagues().then((jobsInProgress) => {
            if (jobsInProgress) {
                setNumberOfJobsInprogressByLeagues(jobsInProgress);
            }
        });
        await crashAnalyticsController.fetchTodayCrashedGames().then((data) => {
            if (data) {
                setFetchCrashedGames(data);
            }
        });
        await crashAnalyticsController.fetchLastWeekCrashedGames().then((data) => {
            if (data) {
                setFetchLastWeekCrashedGames(data);
            }
        });
        await crashAnalyticsController.fetchLastWeekPercentageCrashedGames().then((data) => {
            if (data) {
                setfetchLastWeekPercentageCrashedGames(data);
            }
        });
    }

    return (
        <div>
            <CustomAppBar />
            <CustomModal open={openDeleteModal} setOpen={setOpenDeleteModal}>
                <Grid container spacing={5} alignItems="center" justifyContent="center">
                    <Grid item>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Are you sure you want to delete all jobs?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CustomButton
                            text="DELETE"
                            width={200}
                            height={50}
                            backgroundColor="#a82840"
                            onClick={
                                async () => {
                                    await jobsController.deleteJobs();
                                    setOpenDeleteModal(false);
                                    window.location.reload();
                                }}
                        />
                    </Grid>
                </Grid>
            </CustomModal>
            <List>
                <ListItem>
                    {numberOfJobsByLeagues.length > 0 && <Grid container spacing={5} alignItems="center" justifyContent="center">
                        <Grid item>
                            <CustomPieChart data={numberOfJobsByLeagues} />
                        </Grid>
                        <Grid item>
                            <ListItemText primary={<span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Jobs Created</span>} />
                            <ListItemText>
                                This chart displays the distribution of
                                <br />
                                the number of jobs created for each league.
                            </ListItemText>
                        </Grid>
                    </Grid>}
                    {numberOfJobsByLeagues.length === 0 &&
                        <Grid container spacing={5} alignItems="center" justifyContent="center">
                            <Grid item>
                                <ListItemText primary={<span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>No jobs created</span>} />
                            </Grid>
                        </Grid>}
                </ListItem>
                <Divider component="li" style={dividerStyle} />
                <ListItem>
                    {numberOfJobsInprogressByLeagues.length > 0 && <Grid container spacing={5} alignItems="center" justifyContent="center">
                        <Grid item>
                            <CustomPieChart data={numberOfJobsInprogressByLeagues} />
                        </Grid>
                        <Grid item>
                            <ListItemText primary={<span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Jobs Inprogress by League</span>} />
                            <ListItemText>
                                This chart shows the number of jobs that are
                                <br />
                                currently in progress for each league
                            </ListItemText>
                        </Grid>
                    </Grid>}
                    {numberOfJobsInprogressByLeagues.length === 0 &&
                        <Grid container spacing={5} alignItems="center" justifyContent="center">
                            <Grid item>
                                <ListItemText primary={<span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>No jobs in progress</span>} />
                            </Grid>
                        </Grid>}
                </ListItem>
                <Divider component="li" style={dividerStyle} />
                <Box display="flex" flexDirection="column" alignItems="center">
                    <ListItemText primary={<span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Leagues Status</span>} />
                    {leaguesEnabled.map((league) => {
                        return <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: "340px" }}>
                            <ListItem>
                                <ListItemText primary={league} />
                                <ListItemIcon>
                                    <CheckBoxIcon color="success" />
                                </ListItemIcon>
                            </ListItem>
                        </Box>
                    })}
                    {leaguesDisabled.map((league) => {
                        return <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: "340px" }}>
                            <ListItem>
                                <ListItemText primary={league} />
                                <ListItemIcon>
                                    <HighlightOffIcon color="error" />
                                </ListItemIcon>
                            </ListItem>
                        </Box>
                    })}
                </Box>

                <Divider component="li" style={dividerStyle} />
                <List>
                    <ListItem>
                        <Grid container spacing={5} alignItems="center" justifyContent="center">
                            <Grid item>
                                <Grid container spacing={5} alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <ListItemText primary={<span style={{ fontSize: '1.0em', fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>Crashed Jobs from today </span>} />
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><span style={{ fontSize: '1.0em', fontWeight: 'bold' }}>League</span></TableCell>
                                                <TableCell><span style={{ fontSize: '1.0em', fontWeight: 'bold' }}>Crashed Games</span></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(fetchCrashedGames).map((league) => {
                                                return (
                                                    <TableRow key={league}>
                                                        <TableCell component="th" scope="row">
                                                            {league}
                                                        </TableCell>
                                                        <TableCell align="right">{fetchCrashedGames[league]}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Divider component="li" style={dividerStyle} />
                    <ListItem>
                        <Grid container spacing={5} alignItems="center" justifyContent="center">
                            <Grid item>
                                <Grid container spacing={5} alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <ListItemText primary={<span style={{ fontSize: '1.0em', fontWeight: 'bold' }}>Crashed Jobs from this week</span>} />
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><span style={{ fontSize: '1.0em', fontWeight: 'bold' }}>League</span></TableCell>
                                                <TableCell><span style={{ fontSize: '1.0em', fontWeight: 'bold' }}>Crashed Games</span></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(fetchLastWeekCrashedGames).map((league) => {
                                                return (
                                                    <TableRow key={league}>
                                                        <TableCell component="th" scope="row">
                                                            {league}
                                                        </TableCell>
                                                        <TableCell align="right">{fetchLastWeekCrashedGames[league]}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Divider component="li" style={dividerStyle} />
                    <ListItem>
                        <Grid container spacing={5} alignItems="center" justifyContent="center">
                            <Grid item>
                                <Grid container spacing={5} alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <ListItemText primary={<span style={{ fontSize: '1.0em', fontWeight: 'bold' }}>Percentage of Crashed Jobs from this week</span>} />
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><span style={{ fontSize: '1.0em', fontWeight: 'bold' }}>League</span></TableCell>
                                                <TableCell><span style={{ fontSize: '1.0em', fontWeight: 'bold' }}>Percentage of Crashed Games</span></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(fetchLastWeekPercentageCrashedGames).map((league) => {
                                                return (
                                                    <TableRow key={league}>
                                                        <TableCell component="th" scope="row">
                                                            {league}
                                                        </TableCell>
                                                        <TableCell align="right">{fetchLastWeekPercentageCrashedGames[league]}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>

                <Divider component="li" style={dividerStyle} />
                <ListItem>
                    <Grid container direction="row" spacing={8} alignItems="center" justifyContent="center" padding={8}>
                        <Grid item>
                            <CustomButton text="Delete All Jobs" width={200} height={50} backgroundColor="#a82840" onClick={() => { setOpenDeleteModal(true) }} />
                        </Grid>
                        <Grid item>
                            <CustomButton
                                text="Create All Jobs"
                                width={200}
                                height={50}
                                backgroundColor="#176e2e"
                                onClick={async () => {
                                    await jobsController.createJobs();
                                    window.location.reload();
                                }} />
                        </Grid>
                    </Grid>
                </ListItem>
            </List>
        </div>
    );
};

export default Dashboard;
